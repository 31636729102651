import { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Picture from '../../../assets/logo.png'
import { UserInfo } from '../../../contexts/allContext'
import { SidebarContext } from '../../../contexts/sidebarContext'
import classes from './Sidebar.module.css'

export default function AdminSidebar({ sidebarItems }) {
    const { isOpenSidebar } = useContext(SidebarContext)
    const location = useLocation()

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    return (
        <div>
            <div className={!isOpenSidebar ? classes.sidebar : classes.sidebarHide}>
                <div className={classes.logo}>
                    <Link to="/" title="HEALTHx Admin Panel">
                        {!isOpenSidebar ? <img src={Picture} alt="" /> : <p>HX</p>}
                    </Link>
                </div>
                <ul>
                    {sidebarItems.map((item, i) => (
                        <li key={i}>
                            {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                                <Link to={item.path} className={location.pathname === item.path ? classes.active : ''}>
                                    {item.icon}
                                    {!isOpenSidebar && <span className={classes.text}>{item.text}</span>}
                                </Link>
                            ) : item.path !== '/doctors' &&
                              item.path !== '/employees' &&
                              item.path !== '/corporates' &&
                              item.path !== '/notices' &&
                              item.path !== '/medicines' ? (
                                <Link to={item.path} className={location.pathname === item.path ? classes.active : ''}>
                                    {item.icon}
                                    {!isOpenSidebar && <span className={classes.text}>{item.text}</span>}
                                </Link>
                            ) : (
                                ''
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
