import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import Doc from '../../../../assets/doc/doc-df.jpg'
import { Auth } from '../../../../contexts/allContext'
import { toMonthNameShort } from '../../../../utils/date'
import { ListCount } from '../../../Resource'
import { Pagination } from '../../../index'
import { Loading } from '../../../index'
import classes from './PendingDoctor.module.css'

export default function DoctorList({ pendingDoctors, setOpenActive, setOpenPending, pageSkip, setPageSkip, loading }) {
    const [isOpen, setIsOpen] = useState(false)
    let serial = 0

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const refreshPage = () => {
        window.location.reload()
    }

    const activeId = async (value) => {
        let putFetch = await fetch(`${api}/admin/doctor/activate?id=${value}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (putFetch.ok) {
            refreshPage()
        }
    }

    const openClose = () => {
        setOpenActive(true)
        setOpenPending(false)
    }

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <a href="https://verify.bmdc.org.bd" target="__blank" className={classes.button}>
                    Check BMDC Number
                </a>
                <button className={classes.button} onClick={() => openClose()}>
                    View Active Doctors
                </button>
            </div>

            <ListCount title="Pending Doctor List" results={pendingDoctors} />

            <table className={classes.tableMain}>
                <thead>
                    <tr className={classes.tableRow}>
                        <th>Sl</th>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Doctor Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Specialty</th>
                        <th>Degree</th>
                        <th>BMDC</th>
                        <th>Experience</th>
                        <th>Online Fee</th>
                        <th>Reg. Date</th>
                        <th className={classes.columnWidth}>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingDoctors[1] &&
                        pendingDoctors[1].map((doc, index) =>
                            doc?.User?.is_active === false ? (
                                <tr className={classes.tableRow} key={index}>
                                    <td>{(serial = serial + 1) + pageSkip}</td>
                                    <td>
                                        HX-D
                                        {doc?.Doctor?.id + 100000}
                                    </td>
                                    <td>
                                        <img
                                            src={
                                                doc?.Doctor?.images.length !== 0
                                                    ? doc?.Doctor?.images[0]?.bucket
                                                        ? doc?.Doctor?.images[0]?.image_url
                                                        : `${api}/images/profile/${doc?.Doctor?.images[0]?.image_string}`
                                                    : Doc
                                            }
                                            className={classes.picture}
                                            alt=""
                                        />
                                    </td>
                                    <td>
                                        {doc?.Doctor?.dr_title} {doc?.User?.name}
                                    </td>
                                    <td>{doc?.User?.phone}</td>
                                    <td>
                                        {doc?.User?.email !== `hx${doc?.User?.phone.slice(2, 11)}@gmail.com` &&
                                        doc?.User?.email !== `hx${doc?.User?.phone.slice(2, 11)}@healthxbd.com` &&
                                        doc?.User?.email !== '' ? (
                                            <>
                                                {doc?.User?.email.split('@')[0]}
                                                <br />@{doc?.User?.email.split('@')[1]}
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td>{doc?.DoctorSpeciality?.speciality || '-'}</td>
                                    <td>{doc?.DoctorQualification?.qualification}</td>
                                    <td>{doc?.Doctor?.bmdc}</td>
                                    <td>{doc?.Doctor?.exp_year || '-'}</td>
                                    <td>{doc?.Doctor?.online_fees || '-'}</td>
                                    <td>{`${doc?.Doctor?.created_at.slice(8, 10)} ${toMonthNameShort(
                                        doc?.Doctor?.created_at.slice(5, 7)
                                    )}${doc?.Doctor?.created_at.slice(2, 4)}`}</td>
                                    <td className={classes.status}>
                                        <span className={classes.waiting}>Pending</span>
                                    </td>
                                    <td>
                                        <button className={classes.icon}>
                                            <FontAwesomeIcon icon={faEye} onClick={() => setIsOpen(index)} />
                                        </button>
                                    </td>
                                    {isOpen === index && (
                                        <div key={() => index} className={classes.container}>
                                            <div className={classes.formWrapper}>
                                                <div className={classes.close} onClick={() => setIsOpen(false)}>
                                                    &times;
                                                </div>

                                                <div className={classes.buttonContainer}>
                                                    <button
                                                        className={classes.button}
                                                        onClick={() => {
                                                            activeId(doc?.User?.id)
                                                        }}>
                                                        Active (Yes)
                                                    </button>

                                                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </tr>
                            ) : (
                                ''
                            )
                        )}
                </tbody>
            </table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={pendingDoctors[1]}
                pageAll={pendingDoctors[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
