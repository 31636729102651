import {
    faBriefcaseMedical,
    faCalendarCheck,
    faCheckCircle,
    faCheckToSlot,
    faEdit,
    faHospitalUser,
    faPlus,
    faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import Doc from '../../../../assets/doc/doc-df.jpg'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import { toMonthNameShort } from '../../../../utils/date'
import { ListCount } from '../../../Resource'
import { Pagination } from '../../../index'
import { Loading } from '../../../index'
import classes from './ActiveDoctor.module.css'
import Chamber from './Chamber/Chamber'
import Edit from './Edit/Edit'
import Schedule from './Schedule/Schedule'
import Verify from './Verify/Verify'
import VerifyPartner from './VerifyPartner/VerifyPartner'

export default function ActiveDoctor({
    activeDoctors,
    setOpenActive,
    setOpenPending,
    pageSkip,
    setPageSkip,
    setListOpen,
    setFormOpen,
    loading,
    success,
    setSuccess,
    setCountLimit,
    setPartner,
}) {
    const [chambers, setChambers] = useState([])
    const [workplace, setWorkplace] = useState([])
    const [docId, setDocId] = useState(0)
    const [id, setId] = useState(0)

    const [scheduleOpen, setScheduleOpen] = useState(false)
    const [chamberOpen, setChamberOpen] = useState(false)
    const [verifyOpen, setVerifyOpen] = useState(false)
    const [partnerOpen, setPartnerOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [search, setSearch] = useState('')
    let serial = 0

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [show, setShow] = useState(false)
    const [isloading, setIsLoading] = useState(false)
    const [status, setStatus] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/doctors/workplace/${docId}?skip=0&limit=30`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setWorkplace(data)
                }
            } catch {
                setWorkplace([])
            }
        }
        return fetchData()
    }, [token, docId, success])

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            const response = await fetch(`${api}/doctors/chamberdoctor-chambers/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            const data = await response.json()
            if (response.ok) {
                setChambers(data)
                setIsLoading(false)
                setStatus(false)
            }
        }
        return fetchData()
    }, [token, id, show, status])

    const openClose = () => {
        setOpenActive(false)
        setOpenPending(true)
    }

    const openCloseListForm = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    const limitUp = () => {
        setCountLimit(activeDoctors[0]?.results)
    }

    const filterWork = workplace.filter((work) => work.top_priority === true)
    const work = filterWork[0]

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <div>
                    <input
                        className={classes.searchField}
                        type="text"
                        value={search}
                        placeholder="Search Doctor"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>

                    <select className={classes.select} onChange={(e) => setPartner(e.target.value)}>
                        <option value="">Select</option>
                        <option value="">All</option>
                        <option value={true}>Pharmacy Doctors</option>
                    </select>
                </div>

                <div className={classes.allButton}>
                    <button className={classes.button} onClick={() => openClose()}>
                        View Pending Doctors
                    </button>
                    <span></span>
                    <button className={`${classes.button} ${classes.buttonMargin}`} onClick={() => openCloseListForm()}>
                        + Add Doctors
                    </button>
                </div>
            </div>

            <ListCount title="Active Doctor List" results={activeDoctors} />

            <div className={classes.tablebody}>
                <table className={classes.tableMain}>
                    <thead>
                        <tr className={classes.tableRow}>
                            <th>Sl</th>
                            <th>ID</th>
                            <th>Image</th>
                            <th>Doctor Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Specialty</th>
                            <th>Degree</th>
                            <th>BMDC</th>
                            <th>Experience</th>
                            <th>Online Fee</th>
                            <th>Add-ons</th>
                            <th>Reg. Date</th>
                            <th>Status</th>
                            <th style={{ minWidth: '80px' }}>Action</th>
                            <th>Edit</th>
                            <th style={{ minWidth: '80px' }}>Verify</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeDoctors[1] &&
                            activeDoctors[1]
                                .filter(
                                    (doc) =>
                                        doc.User?.name.toLowerCase().includes(search) ||
                                        doc.User?.phone.toLowerCase().includes(search) ||
                                        doc.User?.email.toLowerCase().includes(search) ||
                                        doc.Doctor?.bmdc.toLowerCase().includes(search) ||
                                        doc.DoctorSpeciality?.speciality.toLowerCase().includes(search) ||
                                        doc.DoctorQualification?.qualification.toLowerCase().includes(search)
                                )
                                .map((doc, index) =>
                                    doc?.User?.is_active === true ? (
                                        <tr className={classes.tableRow} key={index}>
                                            <td>{(serial = serial + 1) + pageSkip}</td>
                                            <td>
                                                HX-D
                                                {doc?.Doctor?.id + 100000}
                                            </td>
                                            <td>
                                                <div className={classes.tick}>
                                                    {doc?.Doctor?.is_verify ? (
                                                        <FontAwesomeIcon
                                                            icon={faCheckCircle}
                                                            className={classes.isVerify}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                    {doc?.Doctor?.is_shown_partner ? (
                                                        <FontAwesomeIcon icon={faPlus} className={classes.isPartner} />
                                                    ) : (
                                                        ''
                                                    )}
                                                    <img
                                                        src={
                                                            doc?.Doctor?.images.length !== 0
                                                                ? doc?.Doctor?.images[0]?.bucket
                                                                    ? doc?.Doctor?.images[0]?.image_url
                                                                    : `${api}/images/profile/${doc?.Doctor?.images[0]?.image_string}`
                                                                : Doc
                                                        }
                                                        className={classes.picture}
                                                        alt=""
                                                    />
                                                </div>
                                            </td>
                                            <td className={classes.cap}>
                                                <a
                                                    href={`https://healthxbd.com/${doc?.User?.id}`}
                                                    target="__blank"
                                                    style={{ color: 'var(--dark)' }}>
                                                    {doc?.Doctor?.dr_title} {doc?.User?.name}
                                                </a>
                                            </td>
                                            <td>{doc?.User?.phone}</td>
                                            <td>
                                                {doc?.User?.email !== `hx${doc?.User?.phone.slice(2, 11)}@gmail.com` &&
                                                doc?.User?.email !==
                                                    `hx${doc?.User?.phone.slice(2, 11)}@healthxbd.com` &&
                                                doc?.User?.email !== '' ? (
                                                    <>
                                                        {doc?.User?.email.split('@')[0]}
                                                        <br />@{doc?.User?.email.split('@')[1]}
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                            <td className={classes.cap}>{doc?.DoctorSpeciality?.speciality || '-'}</td>
                                            <td className={classes.cap}>
                                                {doc?.DoctorQualification?.qualification || '-'}
                                            </td>
                                            <td>{doc?.Doctor?.bmdc}</td>
                                            <td>{doc?.Doctor?.exp_year || '-'}</td>
                                            <td>{doc?.Doctor?.online_fees || '-'}</td>
                                            <td>{doc?.Doctor?.online_total_fees || '-'}</td>
                                            <td>{`${doc?.Doctor?.created_at.slice(8, 10)} ${toMonthNameShort(
                                                doc?.Doctor?.created_at.slice(5, 7)
                                            )}${doc?.Doctor?.created_at.slice(2, 4)}`}</td>
                                            <td className={classes.status}>
                                                <span className={classes.active}>Active</span>
                                            </td>
                                            <td>
                                                <button className={classes.icon}>
                                                    <FontAwesomeIcon
                                                        icon={faCalendarCheck}
                                                        title="Schedule"
                                                        onClick={() => {
                                                            setId(doc?.User?.id)
                                                            setScheduleOpen(index)
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faBriefcaseMedical}
                                                        title="Chamber"
                                                        onClick={() => {
                                                            setId(doc?.User?.id)
                                                            setChamberOpen(index)
                                                        }}
                                                    />
                                                </button>
                                            </td>
                                            <td>
                                                <button className={classes.icon2}>
                                                    <FontAwesomeIcon
                                                        title="Edit"
                                                        icon={faEdit}
                                                        onClick={() => {
                                                            setDocId(doc?.User?.id)
                                                            setEditOpen(index)
                                                        }}
                                                    />
                                                </button>
                                            </td>
                                            <td>
                                                <button className={classes.icon}>
                                                    <FontAwesomeIcon
                                                        title="Verify"
                                                        icon={faCheckToSlot}
                                                        onClick={() => {
                                                            setVerifyOpen(index)
                                                        }}
                                                    />

                                                    <FontAwesomeIcon
                                                        title="Partner Verify"
                                                        icon={faHospitalUser}
                                                        onClick={() => {
                                                            setPartnerOpen(index)
                                                        }}
                                                    />
                                                </button>
                                            </td>

                                            {/* all popup */}
                                            {scheduleOpen === index && (
                                                <Schedule
                                                    index={index}
                                                    setScheduleOpen={setScheduleOpen}
                                                    chambers={chambers}
                                                    doc={doc}
                                                />
                                            )}
                                            {chamberOpen === index && (
                                                <Chamber
                                                    index={index}
                                                    chambers={chambers}
                                                    setChamberOpen={setChamberOpen}
                                                    id={id}
                                                    show={show}
                                                    setShow={setShow}
                                                    isloading={isloading}
                                                    setIsLoading={setIsLoading}
                                                    setStatus={setStatus}
                                                />
                                            )}
                                            {editOpen === index && (
                                                <Edit
                                                    index={index}
                                                    doc={doc}
                                                    work={work}
                                                    setEditOpen={setEditOpen}
                                                    setSuccess={setSuccess}
                                                />
                                            )}
                                            {verifyOpen === index && (
                                                <Verify index={index} doc={doc?.Doctor} setIsOpen={setVerifyOpen} />
                                            )}

                                            {partnerOpen === index && (
                                                <VerifyPartner
                                                    index={index}
                                                    doc={doc?.Doctor}
                                                    setIsOpen={setPartnerOpen}
                                                />
                                            )}
                                        </tr>
                                    ) : (
                                        ''
                                    )
                                )}
                    </tbody>
                </table>
            </div>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={activeDoctors[1]}
                pageAll={activeDoctors[0]?.results}
            />
            <button onClick={() => limitUp()}>Load All</button>
            {loading && <Loading />}
        </div>
    )
}
